<template>
  <div  class="content ma-5 mb-16 px-0 px-sm-16  mx-sm-12" >

    <div class="text-h5 font-weight-bold text-center pt-8 pb-4" >
      <div>{{info.title}}</div>

    </div>

    <v-card class="pa-8 mb-6" v-html="info.content">

    </v-card>









  </div>
</template>

<script>

import shopApi from '@/api/shop'
import { mapState } from 'vuex'



export default {


  data: () => ({
    itemid:0,
    info : {}
    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
    }),
    
  },
  watch:{

      $route(to){
        let itemid = to.query.id
        itemid = parseInt(itemid)

        this.itemid = itemid
        let params = {id:itemid}
        shopApi.help(params).then(res => {
          this.info = res.data
          
          
        })
      }
  },
  created() {

    let itemid = this.$route.query.id
    itemid = parseInt(itemid)

    this.itemid = itemid
    let params = {id:itemid}
    shopApi.help(params).then(res => {
      this.info = res.data
      
      
    })

    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  


</style>
