
import request from './request';


export default new function API() {

  
  this.help = params => request.get({action:"help", ...params});

  

}();
